// Basics
import React from 'react';
import { Container } from 'semantic-ui-react';

// View
import Layout from '../components/Organisms/Layout';
import OrganismBanner from '../components/Organisms/Banner';
import OrganismMainContentWrapper from '../components/Organisms/MainContentWrapper';
import Footer from '../components/Molecules/Footer';

import MoleculesBlogDescription from '../components/Molecules/BlogDescription';


// Assets
import InnerbannerImage from '../assets/images/inner-banner.png';

class PageAboutUs extends React.Component {
  render() {
    const { list } = this.props;
    return (
      <Layout>
        <OrganismBanner
          BackgroundImage={InnerbannerImage}
          className="inner-banner"
          title="A Place for Entrepreneurs to Share and Discover New Stories"
        />
        <OrganismMainContentWrapper>
          <Container>
            <MoleculesBlogDescription />
            <Footer />
          </Container>
        </OrganismMainContentWrapper>
      </Layout>
    )
  }
}

PageAboutUs.defaultProps = {
  list: [
    {
      Category: 'All',
      link: ''
    },
    {
      Category: 'World',
      link: ''
    },
    {
      Category: 'Arts',
      link: ''
    },
    {
      Category: 'Tech',
      link: ''
    },
    {
      Category: 'Business',
      link: ''
    }
  ]
}

export default PageAboutUs;

