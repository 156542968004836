// Basics
import React from 'react';
import './index.scss';
import { Image } from 'semantic-ui-react'
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react'
import {
  Button,
  // Checkbox,
  Form,
  Input,
  // Radio,
  // Select,
  TextArea,
} from 'semantic-ui-react';

// Assets
import imageAvatar from '../../../assets/images/team3.jpg';

class MoleculesBlogDescription extends React.Component {
  render() {
    const { category, description, autherName, createdAt } = this.props;
    return (
      <div className="blog-description-content">
        {
          category &&
          <div className="tags">
            <span className="tag">{category}</span>
          </div>
        }

        {
          description &&
          <p>{description}</p>

        }
        {
          autherName &&
          <span className="auther">by <a href="#">{autherName}</a> , {createdAt}</span>
        }
        <div className="post-tag">
          Tags: <span>PHOTOGRAPHY</span> <span>STORIES</span> <span>CASTLE</span>
        </div>
        <div className="blog-auther">
          <span className="auther-image"><Image src={imageAvatar} /></span>
          <h4>Alec Thompson</h4>
          <p>I've been trying to figure out the bed design for the master bedroom at our Hidden Hills compound...I like good music from Youtube.</p>
          <Button className="follow-button">Follow</Button>
        </div>

        <div className="comment-section">
          <h3>Comments</h3>
          <ul>
            <li>
              <span className="avatar"><Image src={imageAvatar} /></span>
              <h5>Tina Andrew <span className="time">· 7 minutes ago</span></h5>
              <div className="comment-content">
                <p className="small-text">Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed!</p>
                <span className="reply"><Icon name='reply' />Reply</span>
              </div>
              <ul>
                <li>
                  <span className="avatar"><Image src={imageAvatar} /></span>
                  <h5>Tina Andrew <span className="time">· 7 minutes ago</span></h5>
                  <div className="comment-content">
                    <p className="small-text">Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed!</p>
                    <span className="reply"><Icon name='reply' />Reply</span>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <span className="avatar"><Image src={imageAvatar} /></span>
                  <h5>Tina Andrew <span className="time">· 7 minutes ago</span></h5>
                  <div className="comment-content">
                    <p className="small-text">Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed!</p>
                    <span className="reply"><Icon name='reply' />Reply</span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <h3 className="mb-3">Post your comment</h3>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field
                control={Input}
                placeholder='Your Name'
                className="custom-input"
              />
              <Form.Field
                control={Input}
                placeholder='Your Email'
                className="custom-input"
              />
              <Form.Field
                control={Input}
                placeholder='Website URL'
                className="custom-input"
              />
            </Form.Group>

            <Form.Field
              control={TextArea}
              placeholder='Comment'
              className="custom-input"
            />
            <Form.Field control={Button} className="primary-btn">Submit</Form.Field>
          </Form>
        </div>


      </div>
    )
  }
}

MoleculesBlogDescription.defaultProps = {
  category: "ENTERPRISE",
  description: (
    <div>
      <p>This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to see more. We are here to make life better.This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to see more. We are here to make life better.</p>
      <p>This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to see more. We are here to make life better.</p>
      <p>This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to see more. We are here to make life better.</p>
      <p>This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to see more. We are here to make life better.</p>
    </div>
  ),
  autherName: "Mike Butcher",
  createdAt: "2 days ago",
}

MoleculesBlogDescription.propTypes = {
  category: PropTypes.string,
  description: PropTypes.string,
  autherName: PropTypes.string,
  createdAt: PropTypes.string,
}



export default MoleculesBlogDescription;